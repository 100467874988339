import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

type Action = Readonly<{

	/** ID. */
	id: string;

	/** Label. */
	label: string;
}>;

/** Actions sheet data. */
export type SelectSheetData = Readonly<{

	/** Sheet heading. */
	heading: string;

	/** Actions. */
	actions: Action[];

	/** Selected option. */
	selectedId: Action['id'] | null;

	/** On select handler. */
	onSelect: (action: Action) => void;
}>;

/** Select component that's opened as bottom sheet. */
@Component({
	selector: 'wndrc-select-sheet',
	standalone: true,
	imports: [],
	templateUrl: './select-sheet.component.html',
	styleUrl: './select-sheet.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSheetComponent {
	private readonly bottomSheetRef = inject(MatBottomSheetRef<SelectSheetData>);

	/** Options. */
	protected readonly options: SelectSheetData = inject(MAT_BOTTOM_SHEET_DATA);

	/**
	 * Handles action button click.
	 * @param action Action.
	 */
	protected onActionButtonClick(action: Action): void {
		this.options.onSelect(action);
		this.bottomSheetRef.dismiss();
	}
}
