@if (options.heading) {
	<h3 class="heading">{{ options.heading }}</h3>
}
<div class="actions">
	@for (action of options.actions; track action.label) {
		<button
			wndrcButton
			type="button"
			class="sheet-option"
			[class.sheet-option_selected]="action.id === options.selectedId"
			(click)="onActionButtonClick(action)"
		>
			{{ action.label }}
		</button>
	}
</div>
