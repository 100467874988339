import { Injectable, inject } from '@angular/core';

import { Observable, map } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { CallAuth } from '../models/call-session/call-authorization';
import { CallMapper } from '../mappers/call.mapper';

import { callCredentialsSchemaDto } from '../dtos/call-auth.dto';

import { CallCredentials } from '../models/call-session/call-credentials';

import { AppUrlsConfig } from './app-urls.config';

/** Service to manage calls. */
@Injectable({ providedIn: 'root' })
export class CallApiService {

	private readonly callAuthMapper = inject(CallMapper);

	private readonly httpClient = inject(HttpClient);

	private readonly appConfig = inject(AppUrlsConfig);

	/**
	 * Authorize current user for a call.
	 * @param data Data to obtain creds.
	 */
	public getCallCredentials(data: CallAuth): Observable<CallCredentials> {
		const body = this.callAuthMapper.toDto(data);

		return this.httpClient.post<unknown>(this.appConfig.consultations.callCredentials(data.sessionId), body).pipe(
			map(response => callCredentialsSchemaDto.parse(response)),
			map(callCredentialsDto => this.callAuthMapper.fromDto(callCredentialsDto)),
		);
	}
}
