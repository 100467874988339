import { Injectable } from '@angular/core';
import { Observable, from, fromEvent, map, shareReplay, startWith, switchMap } from 'rxjs';

import { PlatformService } from './platform.service';

/** Interface to interact with user devices. */
@Injectable({ providedIn: 'root' })
export class DevicesService {

	private readonly userMediaDevices$ = this.initializeUserMediaDevices$();

	private readonly platformService = new PlatformService();

	/**
	 * Filter device to find audio output device.
	 * @param device Device to filter.
	 * @description Android is broken https://issues.chromium.org/issues/40794926#comment12~
	 * and requires a special filter for audio devices.
	 */
	private readonly filterAudioOutput = this.platformService.os === 'Android' ?
		(device: MediaDeviceInfo) => device.kind === 'audioinput' :
		(device: MediaDeviceInfo) => device.kind === 'audiooutput';

	private readonly filterAudioInput = this.platformService.os === 'Android' ?
		(device: MediaDeviceInfo) => device.kind === 'audiooutput' :
		(device: MediaDeviceInfo) => device.kind === 'audioinput';

	/** Available audio output devices. */
	public readonly audioOutputDevices$ = this.userMediaDevices$.pipe(
		map(devices => devices.filter(this.filterAudioOutput)),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	/** Available audio input devices. */
	public readonly audioInputDevices$ = this.userMediaDevices$.pipe(
		map(devices => devices.filter(this.filterAudioInput)),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	/** Available video devices. */
	public readonly videoInputDevices$ = this.userMediaDevices$.pipe(
		map(devices => devices.filter(device => device.kind === 'videoinput')),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	private initializeUserMediaDevices$(): Observable<MediaDeviceInfo[]> {
		return fromEvent(navigator.mediaDevices, 'devicechange').pipe(
			startWith(null),
			switchMap(() => from(navigator.mediaDevices.enumerateDevices())),
			shareReplay({ refCount: true, bufferSize: 1 }),
		);
	}
}
