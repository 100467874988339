import { WritableSignal, effect, signal } from '@angular/core';

/**
 * Allow to start and finish timer that invokes a passed function once it's done.
 * @param onTimeout Callback that's invoked when timer is done.
 * @param timeout Time in milliseconds after which timer is done.
 * @returns Signal that starts timer when it's true and stops when it's false.
 */
export function createSignalWithTimeout(onTimeout: () => void, timeout = 10000): WritableSignal<boolean> {
	const isTimerActive = signal(false);

	let timer: NodeJS.Timeout | null = null;

	effect(onCleanUp => {
		/*
		 Do not handle case when `isTimerActive` is false because `onCleanUp` is called every time an effect is re-run.
		 So the workflow will be:
		 1. `isTimerActive` is set to true and we start a timer.
		 2. `isTimerActive` is then set to false, `onCleanUp` is called and clears the timer.
		 `effect` use equality function so it won't be triggered twice with the same value.
		*/
		if (isTimerActive()) {
			timer = setTimeout(() => onTimeout(), timeout);
		}
		onCleanUp(() => {
			if (timer) {
				clearTimeout(timer);
				timer = null;
			}
		});
	});

	return isTimerActive;
}
