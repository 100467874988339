import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, InjectionToken, OnDestroy, inject, signal, viewChild } from '@angular/core';
import { MatDialogContent } from '@angular/material/dialog';
import { IonIcon } from '@ionic/angular/standalone';
import { initSession, initPublisher, Session, Publisher, Stream, setAudioOutputDevice, getActiveAudioOutputDevice, PublisherProperties } from '@opentok/client';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { AvatarComponent } from '@wndr/common/shared/components/avatar/avatar.component';
import { IconButtonDirective } from '@wndr/common/shared/components/buttons/icon-button.directive';
import { addIcons } from 'ionicons';
import { call, micOutline, micOffOutline, videocamOutline, videocamOffOutline, cameraReverseOutline, chevronDownOutline, volumeMediumOutline } from 'ionicons/icons';
import { NotificationService } from '@wndr/common/core/services/notification.service';
import { FullNamePipe } from '@wndr/common/shared/pipes/full-name.pipe';
import { LoaderComponent } from '@wndr/common/shared/components/loader/loader.component';
import { createSignalWithTimeout } from '@wndr/common/core/utils/signals/timeout-signal';
import { assertNonNullWithReturn } from '@wndr/common/core/utils/assert-non-null';
import { ButtonDirective } from '@wndr/common/shared/components/buttons/button.directive';

import { MatMenuModule } from '@angular/material/menu';
import { AppPlatform } from '@wndr/common/core/models/platform';

import { DevicesService } from '@wndr/common/core/services/devices.service';
import { map, take } from 'rxjs';
import { filterNull } from '@wndr/common/core/utils/rxjs/filter-null';

import { PlatformService } from '@wndr/common/core/services/platform.service';

import { AppConfig } from '@wndr/common/core/services/app.config';

import { User } from '@wndr/common/core/models/user';

import { DevicesConfigurationComponent } from '../devices-configuration/devices-configuration.component';
import { SessionCallProps } from '../models/session-call-props';

// eslint-disable-next-line max-len
const PERMISSIONS_DENIED_MESSAGE = 'In order to use the video call feature, you need to allow access to your camera and microphone. Please, check your browser settings and reload the page.';

/** Injection token to provide data to VideoCallDialog component. */
export const SESSION_CALL_PROPS = new InjectionToken<SessionCallProps>('VIDEO_CALL_PROPS');

/** Component to perform call for a session. */
@Component({
	selector: 'wndrc-session-call',
	standalone: true,
	imports: [
		MatDialogContent,
		IconButtonDirective,
		IonIcon,
		AvatarComponent,
		FullNamePipe,
		LoaderComponent,
		MatBottomSheetModule,
		ButtonDirective,
		MatMenuModule,
		DevicesConfigurationComponent,
	],
	templateUrl: './session-call.component.html',
	styleUrl: './session-call.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionCallComponent implements AfterViewInit, OnDestroy {

	private readonly appConfig = inject(AppConfig);

	private _publisher: Publisher | null = null;

	private get publisher(): Publisher {
		return assertNonNullWithReturn(this._publisher);
	}

	private _session: Session | null = null;

	private get session(): Session {
		return assertNonNullWithReturn(this._session);
	}

	private readonly notificationService = inject(NotificationService);

	private readonly subscriberEl = viewChild.required<ElementRef<HTMLDivElement>>('subscriber');

	private readonly publisherEl = viewChild.required<ElementRef<HTMLDivElement>>('publisher');

	private readonly devicesService = inject(DevicesService);

	/** Platform service. */
	protected readonly platformService = inject(PlatformService);

	/** Dialog props. */
	protected readonly data = inject(SESSION_CALL_PROPS);

	/** Is video off. */
	protected isVideoOff = false;

	/** Is audio muted. */
	protected isAudioMuted = false;

	/** Is participant connected. */
	protected readonly isParticipantConnected = signal(false);

	/** Is participant audio muted. */
	protected readonly isParticipantAudioBlocked = signal(false);

	/** Is rear camera used. */
	protected readonly isRearCameraUsed = signal(false);

	/** Device ID of currently active audio input. */
	protected readonly activeAudioInputId = signal<string | null>(null);

	/** Device ID of currently active audio output. */
	protected readonly activeAudioOutputId = signal<string | null>(null);

	/** Device ID of currently active video input. */
	protected readonly activeVideoInputId = signal<string | null>(null);

	/** Controls reconnecting state. */
	protected readonly isReconnecting = createSignalWithTimeout(() => {
		this.notificationService.notify('warning', 'Cannot reconnect to the call. Please, try again later.', 5000);
		this.onCallEndClick();
	}, 10000);

	public constructor() {
		addIcons({
			call,
			micOutline,
			micOffOutline,
			videocamOutline,
			videocamOffOutline,
			cameraReverseOutline,
			chevronDownOutline,
			volumeMediumOutline,
		});
	}

	/** Is current session a video call. */
	protected get isVideoCall(): boolean {
		return this.data.isVideoCall;
	}

	/** Toggle video state. Hide and show. */
	protected toggleVideo(): void {
		this.isVideoOff = !this.isVideoOff;
		this.publisher.publishVideo(!this.isVideoOff);
	}

	/** Toggle audio state. Mute and unmute. */
	protected toggleAudio(): void {
		this.isAudioMuted = !this.isAudioMuted;
		this.publisher.publishAudio(!this.isAudioMuted);
	}

	/**
	 * Handle "audioOutputChange" event from devices configuration component.
	 * @param deviceId Device id.
	 */
	protected onAudioOutputSelector(deviceId: string | undefined): void {
		if (deviceId) {
			setAudioOutputDevice(deviceId);

			/*
				This method is called for android devices only.
				For some reason is has multiple issues with webrtc implementation
				and without changing audio source we can't change audio output.
			*/
			this.publisher.setAudioSource(deviceId);
			this.activeAudioOutputId.set(deviceId);
		}
	}

	/**
	 * Handle "audioInputChange" event from devices configuration component.
	 * @param deviceId Device id.
	 */
	protected onAudioInputSelector(deviceId: string): void {
		this.publisher.setAudioSource(deviceId);
		this.activeAudioInputId.set(deviceId);
	}

	/**
	 * Handle "videoInputChange" event from devices configuration component.
	 * @param deviceId Device id.
	 */
	protected onVideoInputSelector(deviceId: string): void {
		this.publisher.setVideoSource(deviceId);
		this.activeVideoInputId.set(deviceId);
	}

	/** Toggle device camera from front to rear and back. */
	protected async cycleVideo(): Promise<void> {
		const isBackCamera = this.isRearCameraUsed();
		const nextCameraTypeId = await this.getCameraDeviceId(isBackCamera ? 'front' : 'back');
		if (typeof nextCameraTypeId === 'string') {
			await this.publisher.setVideoSource(nextCameraTypeId);
			this.isRearCameraUsed.update(value => !value);
		}
	}

	/** @inheritdoc */
	public ngAfterViewInit(): void {
		this.initSession();
	}

	/** @inheritdoc */
	public ngOnDestroy(): void {
		this.session.off('connectionCreated');
		this.session.off('sessionReconnecting');
		this.session.off('sessionReconnected');
		this.session.off('connectionDestroyed');
		this.session.off('streamCreated');
		this.session.off('streamPropertyChanged');
		this.session.disconnect();

		this._publisher?.getAudioSource().stop();
		this._publisher?.getVideoSource().track?.stop();
		this._session = null;
		this._publisher = null;
	}

	/** Handle "click" of end call button .*/
	protected onCallEndClick(): void {
		this.data.onCallEnd();
	}

	/** Handle "click" of hide dialog button. */
	protected onHideClick(): void {
		this.data.onMinimize();
	}

	private initSession(): void {
		const session = initSession(this.appConfig.vonageApiKey, this.data.callCredentials.sessionId);
		this._session = session;

		const options: PublisherProperties = {
			insertMode: 'append',
			width: '100%',
			height: '100%',
			mirror: true,
			resolution: '1280x720',
			name: User.toFullName(this.data.caller),
			videoSource: this.data.isVideoCall,
			style: {
				nameDisplayMode: 'on',
				buttonDisplayMode: 'off',
				audioLevelDisplayMode: 'off',
				backgroundImageURI: this.data.caller.avatarUrl,
			},
		};

		if (!this.data.isVideoCall) {
			options.videoSource = null;
		}

		const publisher = initPublisher(this.publisherEl().nativeElement, options);

		this._publisher = publisher;

		publisher.once('accessDenied', () => {
			this.notificationService.notify('warning', PERMISSIONS_DENIED_MESSAGE, 30000);
		});

		session.connect(this.data.callCredentials.userToken, error => {
			if (error) {
				this.notificationService.notify('warning', 'Error connecting to call. Please, try again later.', 5000);
				this.onCallEndClick();
			} else {
				session.publish(publisher);

				getActiveAudioOutputDevice().then(v => {
					this.activeAudioOutputId.set(v.deviceId ?? null);
				});
				const audioSource = publisher.getAudioSource();

				this.devicesService.audioInputDevices$.pipe(
					take(1),
					map(devices => devices.find(device => device.label === audioSource.label)),
					filterNull(),
				)
					.subscribe(selectedDevice => this.activeAudioInputId.set(selectedDevice.deviceId));
				this.activeVideoInputId.set(publisher.getVideoSource().deviceId);
			}
		});

		session.on('connectionCreated', event => {
			if (event.connection.connectionId !== session.connection?.connectionId) {
				this.isParticipantConnected.set(true);
			}
		});

		session.on('sessionReconnecting', () => {
			this.isReconnecting.set(true);
		});

		session.on('sessionReconnected', () => {
			this.isReconnecting.set(false);
		});

		session.on('connectionDestroyed', () => {
			this.isParticipantConnected.set(false);
		});

		session.on('streamCreated', event => {
			this.isParticipantAudioBlocked.set(!event.stream.hasAudio);
			session.subscribe(
				event.stream,
				this.subscriberEl().nativeElement,
				{
					insertMode: 'append',
					width: '100%',
					height: '100%',
					style: {
						nameDisplayMode: 'on',
						audioBlockedDisplayMode: 'on',
						backgroundImageURI: this.data.participant.avatarUrl,
						buttonDisplayMode: 'off',
						audioLevelDisplayMode: 'off',
					},
					subscribeToAudio: true,
					subscribeToVideo: this.data.isVideoCall,
				},
				error => {
					if (!error) {
						this.isParticipantAudioBlocked.set(!event.stream.hasAudio);
					}
				},
			);
		});

		session.on('streamPropertyChanged', event => {
			if (event.changedProperty === 'hasAudio') {
				if (!this.isCallerStream(event.stream)) {
					this.isParticipantAudioBlocked.set(!event.stream.hasAudio);
				}
			}
		});
	}

	private isCallerStream(stream: Stream): boolean {
		return this.publisher.stream?.streamId === stream.streamId;
	}

	private getCameraDeviceId(type: 'front' | 'back'): Promise<string | null> {
		return new Promise(res => {
			OT.getDevices((_, devices) => {
				if (devices === undefined) {
					res(null);
				} else {
					const substring = AppPlatform.executeByPlatform({
						android: () => type === 'front' ? 'facing front' : 'facing back',
						web: () => type === 'front' ? 'facing front' : 'facing back',
						ios: () => type === 'front' ? 'Front Camera' : 'Back Camera',
					});
					const camera = devices.find(device => device.kind === 'videoInput' && device.label.includes(substring));

					if (camera) {
						res(camera.deviceId);
					} else {
						res(null);
					}
				}
			});
		});
	}
}
